import axios from '@/libs/axios'

export default {
  SET_LOGGEDIN_STATE(state, value) {
    state.isUserLoggedIn = value
  },
  SET_USER(state, value) {
    state.user = value
  },
  SET_ACCESS_TOKEN(state, value) {
    state.accessToken = value
  },
  REMOVE_RECORD(state, itemId) {
    const userIndex = state.users.findIndex(u => u.id === itemId)
    state.users.splice(userIndex, 1)
  },
  SET_BEARER(state, accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  },
}
