import i18nDataEN from './i18nData-en';
import i18nDataDE from './i18nData-de';
import i18nDataFR from './i18nData-fr';
import i18nDataPT from './i18nData-pt';
import i18nDataES from './i18nData-es';
import i18nDataNL from './i18nData-nl';
import i18nDataIT from './i18nData-it';

export default {
    en : i18nDataEN,
    de : i18nDataDE,
    fr : i18nDataFR,
    pt : i18nDataPT,
    es : i18nDataES, // Spanish
    nl : i18nDataNL, // Dutch
    it : i18nDataIT, // Italian
}