// en, de, fr, pt
export default {
  utils: {
    notAuthenticated: {
      youAreNotAuthenticated: "Vous n'êtes pas authentifié.",
      login: "Login",
      toSaveYourData: "pour sauvegarder vos données."
    },
    noDataAvailable: "Pas de données disponibles"
  },
  template: {
    prestige: {
      contactInfo: "INFORMATIONS DE CONTACT",
      skills: "COMPÉTENCES",
      languages: "LANGAGES",
      hobbies: "PASSE-TEMPS",
      careerHistory: "PARCOURS PROFESSIONNEL",
      educationalQualifications: "NIVEAU D'ÉTUDES",
      personalityTraits: "TRAITS DE PERSONNALITÉ",
      competency: "COMPÉTENCE",
      placesIHaveWorkedStudiedBefore: "Lieux où j'ai travaillé/étudié auparavant"
    }
  },
  about: {
    title: "à propos",
    email: "Adresse e-mail",
    youHaveBeenSuccessfullyAuthenticated: "Vous avez été authentifié avec succès",
    enterYourEmailIdToLogin: "Entrez votre email-id pour vous connecter",
    clickToAuthenticate: "Cliquez pour vous authentifier",
    otp: { 
      title: "Entrez OTP",
      verify: "Vérifier"
    },
    enterOTP: "Entrez OTP",
    firstName: "Prénom",
    lastName: "Nom de famille",
    dob: "Date de Naissance",
    gender: {
      male: "Mâle",
      female: "Femelle",
      other: "Autre"
    },
    careerObjective: { 
      title: "Plan de carrière",
      placeholder: "Mes aspirations professionnelles sont..."
    },
    profession: "Profession",
    about: { 
      title: "À propos",
      placeholder: "Un peu de moi... en tant que personne"
    },
    videoPitch: {
      title: "Ajoutez votre pitch vidéo",
      size: "Jusqu'à 10 MB"
    }
  },
  contact: {
    title: "Contacter",
    email: "Adresse e-mail",
    email_2: {
      p1: "Vous verrez votre email-Id ici, une fois que vous",
      p2: "connexion"
    },
    skypeProfile: "Profil Skype",
    contactNumber: "Numéro de contact",
    address: "Adresse",
    personalWebsite: "Site Web personnel",
    facebookProfile: "Profil Facebook",
    twitterProfile: "Profil Twitter",
    linkedinProfile: "Profil LinkedIn"
  },
  careerHistory: {
    title: "Parcours Professionnel",
    editDataToViewHere: "Modifiez les données pour les afficher ici",
    addCareerHistory: "Ajouter un historique de carrière",
    showCareerHistoryOnMap: "Afficher l'historique de carrière sur la carte",
    company: "Compagnie",
    chTitle: "Titre d'emploi",
    startDate: "Date de début",
    endDate: "Date de fin",
    description: "La description",
    country: "Pays",
    city: "Ville",
    iCurrentlyWorkHere: "Je travaille actuellement ici",
    login: "Connexion",
    toAddYourCareerHistory: "pour ajouter votre historique de carrière"
  },
  educationalQualifications: {
    title: "Niveau d'études",
    login: "Connexion",
    toAddYourEducationalQualifications: "pour ajouter vos diplômes",
    editDataToViewHere: "Modifiez les données pour les afficher ici",
    addEducationalQualification: "Ajouter un diplôme",
    degreeCertification: "Diplôme/Certification",
    organization: "Organisation",
    startDate: "Date de début",
    endDate: "Date de fin",
    scorePercentage: "Note/Pourcentage",
    country: "Pays",
    city: "Ville",
    description: "La description",
    orExpected: "Ou attendu"
  },
  skills: {
    title: "Compétences",
    skills: "Compétences",
    languages: "Langages",
    hobbies: "Passe-temps"
  },
  personality: {
    title: "Personnalité",
    chooseYourTop6Strengths: {
      p1: "Choisissez vos 6 principaux points forts (Caractéristiques qui",
      p2: "MEILLEUR",
      p3: "décris-toi)"
    },
    characteristicsThatBestDescribeYou: {
      p1: "Caractéristiques qui",
      p2: "MEILLEUR",
      p3: "décris-toi"
    },
    choose6MoreStrengths: {
      p1: "Choisissez 6 autres forces (Caractéristiques qui",
      p2: "LES PLUS",
      p3: "décris-toi)"
    },
    characteristicsThatMostDescribeYou: {
      p1: "Caractéristiques qui",
      p2: "LES PLUS",
      p3: "décris-toi"
    },
    characteristicsThatLeastDescribeYou: {
      p1: "Caractéristiques qui",
      p2: "MOINS",
      p3: "décris-toi"
    },
    keepBuildingYourPersonalBrand: "Continuez à construire votre marque personnelle",
    dontBroadcastMyPersonality: "Ne diffusez pas ma personnalité",
    statements: "Déclarations",
    cloudTags: "Balises Nuage",
    icons: "Icônes",
    personalityStatements: "Déclarations de personnalité"
  },
  projects: {
    title: "Projets",
    login: "Connexion",
    toAddYourProjects: "pour ajouter vos projets",
    editDataToViewHere: "Modifiez les données pour les afficher ici",
    addProject: "Ajouter un projet",
    projectTitle: "Titre",
    organization: "Organisation",
    addProjectPicture: "Ajouter une image de projet",
    updateProjectPicture: "Mettre à jour l'image du projet",
    upto3Mb: "Jusqu'à 3 MB",
    description: "La description"
  },
  logout: {
    title: "Se déconnecter"
  },
  editorRightBar: {
    talentCard: 'Carte de talent',
    buildYourTalentProfileWith : `Construisez votre profil de talent avec {organizationName}`,
    alex: "Alex",
    alexForContinuousLearning: "Gestionnaire d'expérience d'apprentissage adaptatif pour l'apprentissage continu",
    templateOptions: "Options de modèle",
    template: "Modèle",
    preview: "Aperçu",
    download: "Télécharger",
    copyLink: "Copier le lien",
    published: "Publié",
    private: "Privé",
    colorSettings: "Paramètres de couleur"
  }
}
