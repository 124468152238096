// en, de, fr, pt
export default {
  utils: {
    notAuthenticated: {
      youAreNotAuthenticated: "No estas autenticado",
      login: "Login",
      toSaveYourData: "para guardar sus datos."
    },
    noDataAvailable: "Datos no disponibles"
  },
  template: {
    prestige: {
      contactInfo: "DATOS DE CONTACTO",
      skills: "HABILIDADES",
      languages: "IDIOMAS",
      hobbies: "AFICIONES",
      careerHistory: "HISTORIA DE CARRERAS",
      educationalQualifications: "PREPARACIÓN ACADÉMICA",
      personalityTraits: "RASGOS DE PERSONALIDAD",
      competency: "COMPETENCIA",
      placesIHaveWorkedStudiedBefore: "Lugares en los que he trabajado / estudiado antes"
    }
  },
  about: {
    title: "Acerca de",
    email: "Dirección de correo electrónico",
    youHaveBeenSuccessfullyAuthenticated: "Ha sido autenticado exitosamente",
    enterYourEmailIdToLogin: "Ingrese su correo electrónico para iniciar sesión",
    clickToAuthenticate: "Haga clic para autenticarse",
    otp: { 
      title: "Ingrese OTP",
      verify: "Verificar"
    },
    enterOTP: "Ingrese OTP",
    firstName: "Primer nombre",
    lastName: "Apellido",
    dob: "Fecha de nacimiento",
    gender: {
      male: "Masculino",
      female: "Mujer",
      other: "Otro"
    },
    careerObjective: { 
      title: "Objetivo de Carrera",
      placeholder: "Mis aspiraciones profesionales son..."
    },
    profession: "Profesión",
    about: { 
      title: "Acerca de",
      placeholder: "Un poco de mi.. como persona"
    },
    videoPitch: {
      title: "Agregue su presentación de video",
      size: "Hasta 10 MB"
    }
  },
  contact: {
    title: "Contacto",
    email: "Dirección de correo electrónico",
    email_2: {
      p1: "Verá su ID de correo electrónico aquí, una vez que ",
      p2: "inicie sesión"
    },
    skypeProfile: "Perfil de Skype",
    contactNumber: "Número de contacto",
    address: "Habla a",
    personalWebsite: "Sitio web personal",
    facebookProfile: "Perfil de Facebook",
    twitterProfile: "Perfil de Twitter",
    linkedinProfile: "Perfil de Linkedin"
  },
  careerHistory: {
    title: "Historia de Carreras",
    editDataToViewHere: "Edite los datos para verlos aquí",
    addCareerHistory: "Agregar historial de carrera",
    showCareerHistoryOnMap: "Mostrar historial de carrera en el mapa",
    company: "Empresa",
    chTitle: "Título",
    startDate: "Fecha de inicio",
    endDate: "fecha final",
    description: "Descripción",
    country: "País",
    city: "Ciudad",
    iCurrentlyWorkHere: "actualmente trabajo aquí",
    login: "inicie sesión ",
    toAddYourCareerHistory: "para agregar su historial profesional"
  },
  educationalQualifications: {
    title: "Preparación académica",
    login: "inicie sesión ",
    toAddYourEducationalQualifications: "para agregar sus calificaciones educativas",
    editDataToViewHere: "Edite los datos para verlos aquí",
    addEducationalQualification: "Agregar calificación educativa",
    degreeCertification: "Título / Certificación",
    organization: "Organización",
    startDate: "Fecha de inicio",
    endDate: "Fecha final",
    scorePercentage: "Puntuación / porcentaje",
    country: "País",
    city: "Ciudad",
    description: "Descripción",
    orExpected: "O esperado"
  },
  skills: {
    title: "Habilidades",
    skills: "Habilidades",
    languages: "Idiomas",
    hobbies: "Aficiones"
  },
  personality: {
    title: "Personalidad",
    chooseYourTop6Strengths: {
      p1: "Elija sus 6 principales fortalezas (características que",
      p2: "MEJOR",
      p3: "lo describen)"
    },
    characteristicsThatBestDescribeYou: {
      p1: "Características que",
      p2: "MEJOR",
      p3: "lo describen"
    },
    choose6MoreStrengths: {
      p1: "Elija 6 fortalezas más (características que",
      p2: "MÁS",
      p3: "lo describen)"
    },
    characteristicsThatMostDescribeYou: {
      p1: "Características que",
      p2: "MÁS",
      p3: "lo describen"
    },
    characteristicsThatLeastDescribeYou: {
      p1: "Características que",
      p2: "MENOS",
      p3: "lo describen"
    },
    keepBuildingYourPersonalBrand: "Sigue construyendo tu marca personal",
    dontBroadcastMyPersonality: "No difunda mi personalidad",
    statements: "Declaraciones",
    cloudTags: "Etiquetas en la nube",
    icons: "Iconos",
    personalityStatements: "Declaraciones de personalidad"
  },
  projects: {
    title: "Proyectos",
    login: "Inicie sesión ",
    toAddYourProjects: "para agregar sus proyectos",
    editDataToViewHere: "Edite los datos para verlos aquí",
    addProject: "Agregar proyecto",
    projectTitle: "Título",
    organization: "Organización",
    addProjectPicture: "Agregar imagen de proyecto",
    updateProjectPicture: "Actualizar la imagen del proyecto",
    upto3Mb: "Hasta 3 MB",
    description: "Descripción"
  },
  logout: {
    title: "Cerrar sesión"
  },
  editorRightBar: {
    talentCard: 'Tarjeta de talento',
    buildYourTalentProfileWith : `Cree su perfil de talento con {organizationName}`,
    alex: "Alex",
    alexForContinuousLearning: "Gestor de experiencias de aprendizaje adaptativo para el aprendizaje continuo",
    templateOptions: "Opciones de plantilla",
    template: "Plantilla",
    preview: "Avance",
    download: "Descargar",
    copyLink: "Copiar link",
    published: "Publicado",
    private: "Privado",
    colorSettings: "Configuraciones de color"
  }
}
