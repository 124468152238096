import i18nData from "./editor/i18nData";

// en, de, fr, pt
export default {
  en: {
    editor: i18nData.en,
  },
  de: {
    editor: i18nData.de,
  },
  fr: {
    editor: i18nData.fr,
  },
  pt: {
    editor: i18nData.pt,
  },
  es: {
    // Spanish
    editor: i18nData.es,
  },
  nl: {
    // Dutch
    editor: i18nData.nl,
  },
  it: {
    // Italian
    editor: i18nData.it,
  },
};
