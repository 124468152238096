// en, de, fr, pt
export default {
  utils: {
    notAuthenticated: {
      youAreNotAuthenticated: "U bent niet geverifieerd.",
      login: "Login",
      toSaveYourData: "om uw gegevens op te slaan."
    },
    noDataAvailable: "Geen gegevens beschikbaar"
  },
  template: {
    prestige: {
      contactInfo: "CONTACT INFORMATIE",
      skills: "VAARDIGHEDEN",
      languages: "TALEN",
      hobbies: "HOBBY'S",
      careerHistory: "CARRIERE GESCHIEDENIS",
      educationalQualifications: "ONDERWIJSKWALIFICATIES",
      personalityTraits: "KARAKTER EIGENSCHAPPEN",
      competency: "COMPETENTIE",
      placesIHaveWorkedStudiedBefore: "Plaatsen waar ik eerder heb gewerkt/gestudeerd"
    }
  },
  about: {
    title: "Over",
    email: "E-mailadres",
    youHaveBeenSuccessfullyAuthenticated: "Je bent succesvol geauthenticeerd",
    enterYourEmailIdToLogin: "Voer je e-mailadres in om in te loggen",
    clickToAuthenticate: "Klik om te verifiëren",
    otp: { 
      title: "Voer OTP in",
      verify: "Verifiëren"
    },
    enterOTP: "Voer OTP in",
    firstName: "Voornaam",
    lastName: "Achternaam",
    dob: "Geboortedatum",
    gender: {
      male: "Mannetje",
      female: "Vrouw",
      other: "Andere"
    },
    careerObjective: { 
      title: "Carrière doel",
      placeholder: "Mijn loopbaanambities zijn..."
    },
    profession: "Beroep",
    about: { 
      title: "Over",
      placeholder: "Een beetje over mij.. als persoon"
    },
    videoPitch: {
      title: "Voeg uw videopitch toe",
      size: "Tot 10 MB"
    }
  },
  contact: {
    title: "Contact",
    email: "E-mailadres",
    email_2: {
      p1: "U ziet uw e-mail-ID hier, zodra u ",
      p2: "login"
    },
    skypeProfile: "Skype-profiel",
    contactNumber: "Contact nummer",
    address: "Adres",
    personalWebsite: "Persoonlijke website",
    facebookProfile: "Facebook-profiel",
    twitterProfile: "Twitter-profiel",
    linkedinProfile: "LinkedIn-profiel"
  },
  careerHistory: {
    title: "Carriere Geschiedenis",
    editDataToViewHere: "Gegevens bewerken om hier te bekijken",
    addCareerHistory: "Carrièregeschiedenis toevoegen",
    showCareerHistoryOnMap: "Toon loopbaangeschiedenis op kaart",
    company: "Bedrijf",
    chTitle: "Titel",
    startDate: "Startdatum",
    endDate: "einddatum",
    description: "Omschrijving",
    country: "Land",
    city: "Stad",
    iCurrentlyWorkHere: "Ik werk momenteel hier",
    login: "Login",
    toAddYourCareerHistory: "om je loopbaangeschiedenis toe te voegen"
  },
  educationalQualifications: {
    title: "onderwijskwalificaties",
    login: "Login",
    toAddYourEducationalQualifications: "om uw onderwijskwalificaties toe te voegen",
    editDataToViewHere: "Gegevens bewerken om hier te bekijken",
    addEducationalQualification: "Educatieve kwalificatie toevoegen",
    degreeCertification: "Graad/Certificering",
    organization: "Organisatie",
    startDate: "Startdatum",
    endDate: "Einddatum",
    scorePercentage: "Score/Percentage",
    country: "Land",
    city: "Stad",
    description: "Omschrijving",
    orExpected: "of verwacht"
  },
  skills: {
    title: "Vaardigheden",
    skills: "Vaardigheden",
    languages: "Talen",
    hobbies: "Hobby's"
  },
  personality: {
    title: "Persoonlijkheid",
    chooseYourTop6Strengths: {
      p1: "Kies je top 6 sterke punten (kenmerken die",
      p2: "HET BESTE",
      p3: "beschrijf je)"
    },
    characteristicsThatBestDescribeYou: {
      p1: "Kenmerken die",
      p2: "HET BESTE",
      p3: "beschrijf je"
    },
    choose6MoreStrengths: {
      p1: "Kies nog 6 sterke punten (kenmerken die",
      p2: "MOST",
      p3: "beschrijf je)"
    },
    characteristicsThatMostDescribeYou: {
      p1: "Kenmerken die",
      p2: "MEEST",
      p3: "beschrijf je"
    },
    characteristicsThatLeastDescribeYou: {
      p1: "Kenmerken die",
      p2: "MINST",
      p3: "beschrijf je"
    },
    keepBuildingYourPersonalBrand: "Blijf bouwen aan je persoonlijke merk",
    dontBroadcastMyPersonality: "Zend mijn persoonlijkheid niet uit",
    statements: "Verklaringen",
    cloudTags: "Cloud-tags",
    icons: "Pictogrammen",
    personalityStatements: "Persoonlijkheidsverklaringen"
  },
  projects: {
    title: "Projecten",
    login: "Login",
    toAddYourProjects: "om uw projecten toe te voegen",
    editDataToViewHere: "Gegevens bewerken om hier te bekijken",
    addProject: "Project toevoegen",
    projectTitle: "Titel",
    organization: "Organisatie",
    addProjectPicture: "Projectfoto toevoegen",
    updateProjectPicture: "Projectfoto bijwerken",
    upto3Mb: "Tot 3 MB",
    description: "Omschrijving"
  },
  logout: {
    title: "Logout"
  },
  editorRightBar: {
    talentCard: 'Talentenkaart',
    buildYourTalentProfileWith : `Bouw je talentprofiel op met {organizationName}`,
    alex: "Alex",
    alexForContinuousLearning: "Adaptive Learning EXperience manager voor continu leren",
    templateOptions: "Sjabloonopties",
    template: "Sjabloon",
    preview: "Voorbeeld",
    download: "Downloaden",
    copyLink: "Kopieer link",
    published: "gepubliceerd",
    private: "Privaat",
    colorSettings: "Kleurinstellingen"
  }
}
