// en, de, fr, pt
export default {
  utils: {
    notAuthenticated: {
      youAreNotAuthenticated: "Sie sind nicht authentifiziert.",
      login: "Login",
      toSaveYourData: "um Ihre Daten zu speichern."
    },
    noDataAvailable: "Keine Daten verfügbar"
  },
  template: {
    prestige: {
      contactInfo: "KONTAKTINFORMATION",
      skills: "KOMPETENZEN",
      languages: "SPRACHEN",
      hobbies: "HOBBIES",
      careerHistory: "KARRIERE GESCHICHTE",
      educationalQualifications: "BILDUNGSABSCHLÜSSE",
      personalityTraits: "PERSÖNLICHKEITSMERKMALE",
      competency: "KOMPETENZ",
      placesIHaveWorkedStudiedBefore: "Orte, an denen ich zuvor gearbeitet/studiert habe"
    }
  },
  about: {
    title: "Über",
    email: "E-Mail-Addresse",
    youHaveBeenSuccessfullyAuthenticated: "Sie wurden erfolgreich authentifiziert",
    enterYourEmailIdToLogin: "Geben Sie Ihre E-Mail-ID ein, um sich anzumelden",
    clickToAuthenticate: "Klicken Sie, um sich zu authentifizieren",
    otp: { 
      title: "OTP eingeben",
      verify: "Überprüfen"
    },
    enterOTP: "OTP eingeben",
    firstName: "Vorname",
    lastName: "Nachname",
    dob: "Geburtsdatum",
    gender: {
      male: "Männlich",
      female: "Weiblich",
      other: "Andere"
    },
    careerObjective: { 
      title: "Karriere-Ziel",
      placeholder: "Mein Berufswunsch ist..."
    },
    profession: "Beruf",
    about: { 
      title: "Über",
      placeholder: "Ein wenig über mich.. als Person"
    },
    videoPitch: {
      title: "Fügen Sie Ihren Video-Pitch hinzu",
      size: "Bis zu 10 MB"
    }
  },
  contact: {
    title: "Kontakt",
    email: "E-Mail-Addresse",
    email_2: {
      p1: "Hier sehen Sie Ihre E-Mail-ID, sobald Sie ",
      p2: "login"
    },
    skypeProfile: "Skype-Profil",
    contactNumber: "Kontakt Nummer",
    address: "Addresse",
    personalWebsite: "Persönliche Webseite",
    facebookProfile: "Facebook-Profil",
    twitterProfile: "Twitter-Profil",
    linkedinProfile: "LinkedIn-Profil"
  },
  careerHistory: {
    title: "Karriere Geschichte",
    editDataToViewHere: "Daten bearbeiten, um sie hier anzuzeigen",
    addCareerHistory: "Karriereverlauf hinzufügen",
    showCareerHistoryOnMap: "Karriereverlauf auf Karte anzeigen",
    company: "Unternehmen",
    chTitle: "Titel",
    startDate: "Anfangsdatum",
    endDate: "Endtermin",
    description: "Beschreibung",
    country: "Land",
    city: "Stadt",
    iCurrentlyWorkHere: "Derzeit arbeite ich hier",
    login: "Login",
    toAddYourCareerHistory: "um Ihren Karriereverlauf hinzuzufügen"
  },
  educationalQualifications: {
    title: "Bildungsabschlüsse",
    login: "Login",
    toAddYourEducationalQualifications: "um Ihre Bildungsabschlüsse hinzuzufügen",
    editDataToViewHere: "Daten bearbeiten, um sie hier anzuzeigen",
    addEducationalQualification: "Bildungsabschluss hinzufügen",
    degreeCertification: "Abschluss/Zertifizierung",
    organization: "Organisation",
    startDate: "Anfangsdatum",
    endDate: "Endtermin",
    scorePercentage: "Punktzahl/Prozentsatz",
    country: "Land",
    city: "Stadt",
    description: "Beschreibung",
    orExpected: "Oder erwartet"
  },
  skills: {
    title: "Kompetenzen",
    skills: "Kompetenzen",
    languages: "Sprachen",
    hobbies: "Hobbys"
  },
  personality: {
    title: "Persönlichkeit",
    chooseYourTop6Strengths: {
      p1: "Wählen Sie Ihre Top-6-Stärken (Eigenschaften, die",
      p2: "BESTE",
      p3: "beschreibe dich)"
    },
    characteristicsThatBestDescribeYou: {
      p1: "Eigenschaften, die",
      p2: "BESTE",
      p3: "beschreibe dich"
    },
    choose6MoreStrengths: {
      p1: "Wählen Sie 6 weitere Stärken (Eigenschaften, die",
      p2: "DIE MEISTEN",
      p3: "beschreibe dich)"
    },
    characteristicsThatMostDescribeYou: {
      p1: "Eigenschaften, die",
      p2: "DIE MEISTEN",
      p3: "beschreibe dich"
    },
    characteristicsThatLeastDescribeYou: {
      p1: "Eigenschaften, die",
      p2: "AM WENIGSTEN",
      p3: "beschreibe dich"
    },
    keepBuildingYourPersonalBrand: "Bauen Sie weiter Ihre persönliche Marke auf",
    dontBroadcastMyPersonality: "Sende nicht meine Persönlichkeit",
    statements: "Aussagen",
    cloudTags: "Cloud-Tags",
    icons: "Symbole",
    personalityStatements: "Persönlichkeitsaussagen"
  },
  projects: {
    title: "Projekte",
    login: "Login",
    toAddYourProjects: "um Ihre Projekte hinzuzufügen",
    editDataToViewHere: "Daten bearbeiten, um sie hier anzuzeigen",
    addProject: "Projekt hinzufügen",
    projectTitle: "Titel",
    organization: "Organisation",
    addProjectPicture: "Projektbild hinzufügen",
    updateProjectPicture: "Projektbild aktualisieren",
    upto3Mb: "Bis zu 3 MB",
    description: "Beschreibung"
  },
  logout: {
    title: "Ausloggen"
  },
  editorRightBar: {
    talentCard: 'Talentkarte',
    buildYourTalentProfileWith : `Erstellen Sie Ihr Talentprofil mit {organizationName}`,
    alex: "Alex",
    alexForContinuousLearning: "Adaptive Learning EXPerience Manager für kontinuierliches Lernen",
    templateOptions: "Vorlagenoptionen",
    template: "Vorlage",
    preview: "Vorschau",
    download: "Herunterladen",
    copyLink: "Link kopieren",
    published: "Veröffentlicht",
    private: "Privatgelände",
    colorSettings: "Farbeinstellungen"
  }
}
