// en, de, fr, pt
export default {
  utils: {
    notAuthenticated: {
      youAreNotAuthenticated: "You are not authenticated.",
      login: "Login",
      toSaveYourData: "to save your data.",
    },
    noDataAvailable: "No Data Available",
  },
  template: {
    prestige: {
      contactInfo: "CONTACT INFO",
      skills: "SKILLS",
      languages: "LANGUAGES",
      hobbies: "HOBBIES",
      careerHistory: "CAREER HISTORY",
      educationalQualifications: "EDUCATIONAL QUALIFICATIONS",
      personalityTraits: "PERSONALITY TRAITS",
      competency: "COMPETENCY",
      placesIHaveWorkedStudiedBefore: "Places I have Worked/Studied before",
    },
  },
  about: {
    title: "About",
    email: "Email Address",
    youHaveBeenSuccessfullyAuthenticated:
      "You have been successfully authenticated",
    enterYourEmailIdToLogin: "Enter you email-id to login",
    clickToAuthenticate: "Click to authenticate",
    otp: {
      title: "Enter OTP",
      verify: "Verify",
    },
    enterOTP: "Enter OTP",
    firstName: "First Name",
    lastName: "Last Name",
    dob: "Date of Birth",
    gender: {
      male: "Male",
      female: "Female",
      other: "Other",
    },
    careerObjective: {
      title: "Career Objective",
      placeholder: "My Career Aspirations are...",
    },
    profession: "Profession",
    about: {
      title: "About",
      placeholder: "A little about me.. as a person",
    },
    videoPitch: {
      title: "Add Your Video Pitch",
      size: "Upto 10 MB",
    },
    resumeParser: {
      title: "Upload resume",
      size: "Parse a word or PDF doc of size up to 5 MB",
    },
  },
  contact: {
    title: "Contact",
    email: "Email Address",
    email_2: {
      p1: "You will see your Email-Id here, once you ",
      p2: "login",
    },
    skypeProfile: "Skype Profile",
    contactNumber: "Contact Number",
    address: "Address",
    personalWebsite: "Personal Website",
    facebookProfile: "Facebook Profile",
    twitterProfile: "Twitter Profile",
    linkedinProfile: "LinkedIn Profile",
    discordProfile: "Discord Profile",
  },
  careerHistory: {
    title: "Career History",
    editDataToViewHere: "Edit data to view here",
    addCareerHistory: "Add Career History",
    showCareerHistoryOnMap: "Show Career History on Map",
    company: "Company",
    chTitle: "Title",
    startDate: "Start Date",
    endDate: "endDate",
    description: "Description",
    country: "Country",
    city: "City",
    iCurrentlyWorkHere: "I currently work here",
    login: "Login",
    toAddYourCareerHistory: "to add your Career History",
  },
  educationalQualifications: {
    title: "Educational Qualifications",
    login: "Login",
    toAddYourEducationalQualifications:
      "to add your Educational Qualifications",
    editDataToViewHere: "Edit data to view here",
    addEducationalQualification: "Add Educational Qualification",
    degreeCertification: "Degree/Certification",
    organization: "Organization",
    startDate: "Start Date",
    endDate: "End Date",
    scorePercentage: "Score/Percentage",
    country: "Country",
    city: "City",
    description: "Description",
    orExpected: "Or Expected",
  },
  skills: {
    title: "Skills",
    skills: "Skills",
    languages: "Languages",
    hobbies: "Hobbies",
  },
  personality: {
    title: "Abilities",
    chooseYourTop6Strengths: {
      p1: "Choose the top 6 strengths (abilities that you  are",
      p2: "BEST",
      p3: "known for)",
    },
    characteristicsThatBestDescribeYou: {
      p1: "Abilities that you are",
      p2: "BEST",
      p3: "known for",
    },
    choose6MoreStrengths: {
      p1: `Choose next 6 attributes (abilities that you are`,
      p2: "SOMEWHAT",
      p3: "known for)",
    },
    characteristicsThatMostDescribeYou: {
      p1: "Abilities that you are",
      p2: "SOMEWHAT",
      p3: "known for",
    },
    choose6LeastStrengths: {
      p1: "Choose next 6 attributes (abilities that you are",
      p2: "LEAST",
      p3: "known for)",
    },
    characteristicsThatLeastDescribeYou: {
      p1: "Abilities that you are",
      p2: "LEAST",
      p3: "known for",
    },
    keepBuildingYourPersonalBrand: "Keep building your personal brand",
    dontBroadcastMyPersonality: "Don't Broadcast my Personality",
    statements: "Statements",
    cloudTags: "Cloud Tags",
    icons: "Icons",
    personalityStatements: "Ability Statements",
  },
  projects: {
    title: "Projects",
    login: "Login",
    toAddYourProjects: "to add your Projects",
    editDataToViewHere: "Edit data to view here",
    addProject: "Add Project",
    projectTitle: "Title",
    organization: "Organization",
    addProjectPicture: "Add Project Picture",
    updateProjectPicture: "Update Project Picture",
    upto3Mb: "Upto 3 MB",
    description: "Description",
  },
  logout: {
    title: "Logout",
  },
  editorRightBar: {
    talentCard: "Talent Card",
    buildYourTalentProfileWith: `Build your talent profile with {organizationName}`,
    alex: "Alex",
    alexForContinuousLearning:
      "Adaptive Learning EXperience manager for continuous learning",
    templateOptions: "Options",
    template: "Template",
    preview: "Preview",
    download: "Download",
    copyLink: "Copy Link",
    published: "Published",
    private: "Private",
    colorSettings: "Color Settings",
  },
  endorsement: {
    title: "Abilities",
    chooseYourTop6Strengths: {
      p1: "Choose the top 6 strengths (abilities that {name} is",
      p2: "BEST",
      p3: "known for)",
    },
    characteristicsThatBestDescribeYou: {
      p1: "Abilities that {name} is",
      p2: "BEST",
      p3: "known for",
    },
    choose6MoreStrengths: {
      p1: `Choose next 6 attributes (abilities that {name} is`,
      p2: "SOMEWHAT",
      p3: "known for)",
    },
    characteristicsThatMostDescribeYou: {
      p1: "Abilities that {name} is",
      p2: "SOMEWHAT",
      p3: "known for",
    },
    choose6LeastStrengths: {
      p1: "Choose next 6 attributes (abilities that {name} is",
      p2: "LEAST",
      p3: "known for)",
    },
    characteristicsThatLeastDescribeYou: {
      p1: "Abilities that {name} is",
      p2: "LEAST",
      p3: "known for",
    },
    keepBuildingYourPersonalBrand: "Keep building {name} personal brand",
    dontBroadcastMyPersonality: "Don't Broadcast {name} Personality",
    statements: "Statements",
    cloudTags: "Cloud Tags",
    icons: "Icons",
    personalityStatements: "Ability Statements",
  },
};
