import axios from "@/libs/axios";
import defaultAxios from "axios";
import constants from "@/constants";
import { logOut } from "@/auth/utils";

export default {
  logout() {
    logOut();
    axios.defaults.headers.common.Authorization = "";
  },

  async login(_, authData) {
    const userDetails = await defaultAxios.post(
      `${constants.loginServiceUrl}/verify-otp`,
      authData
    );
    return userDetails.data;
  },

  async sendMeMagicLink(_, userDetails) {
    const response = await axios.post(
      `${constants.loginServiceUrl}/magic-link`,
      userDetails
    );
    return response.data;
  },

  async decryptInvitePhrase(_, { invitePhrase }) {
    const inviteData = await defaultAxios.post(
      `${constants.loginServiceUrl}/invite/decrypt`,
      { invitePhrase }
    );
    return inviteData.data;
  },
};
