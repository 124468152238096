// en, de, fr, pt
export default {
  utils: {
    notAuthenticated: {
      youAreNotAuthenticated: "Non sei autenticato.",
      login: "Accedi",
      toSaveYourData: "per salvare i tuoi dati."
    },
    noDataAvailable: "Nessun dato disponibile"
  },
  template: {
    prestige: {
      contactInfo: "INFORMAZIONI DI CONTATTO",
      skills: "Competenze",
      languages: "LINGUE",
      hobbies: "INTERESSI",
      careerHistory: "ESPERIENZE LAVORATIVE",
      educationalQualifications: "FORMAZIONE",
      personalityTraits: "CARATTERISTICHE PERSONALI",
      competency: "COMPETENZA",
      placesIHaveWorkedStudiedBefore: "Posti dove ho lavorato/studiato"
    }
  },
  about: {
    title: "Profilo",
    email: "Indirizzo Email",
    youHaveBeenSuccessfullyAuthenticated: "Sei stato autenticato",
    enterYourEmailIdToLogin: "Inserisci il tuo indirizzo email-id per accedere",
    clickToAuthenticate: "Clicca per accedere",
    otp: { 
      title: "Inserisci OTP",
      verify: "Verifica"
    },
    enterOTP: "Inserisci OTP",
    firstName: "Nome",
    lastName: "Cognome",
    dob: "Data di nascita",
    gender: {
      male: "Maschio",
      female: "Femmina",
      other: "Altro"
    },
    careerObjective: { 
      title: "Obiettivi professionali",
      placeholder: "Le mie ambizioni professionali sono..."
    },
    profession: "Professione",
    about: { 
      title: "Chi sono",
      placeholder: "Qualche info su di me..."
    },
    videoPitch: {
      title: "Aggiungi il tuo video discorso",
      size: "Fino a 10 MB"
    }
  },
  contact: {
    title: "Contatti",
    email: "Indirizzo Email",
    email_2: {
      p1: "Visualizzerai il tuo Email-Id qui, dopo il",
      p2: "login"
    },
    skypeProfile: "Profilo Skype",
    contactNumber: "Numero di contatto",
    address: "Indirizzo",
    personalWebsite: "Sito web Personale",
    facebookProfile: "Profilo Facebook",
    twitterProfile: "Profilo Twitter",
    linkedinProfile: "Profilo LinkedIn"
  },
  careerHistory: {
    title: "Esperienze professionali",
    editDataToViewHere: "Modifica qui",
    addCareerHistory: "Aggiungi esperienza professionale",
    showCareerHistoryOnMap: "Mostra le tue esperienze professionali sulla mappa",
    company: "Azienda",
    chTitle: "Posizione lavorativa",
    startDate: "Data di inizio",
    endDate: "Data di fine",
    description: "Descrizione",
    country: "Paese",
    city: "Città",
    iCurrentlyWorkHere: "Attualmente lavoro qui",
    login: "Accedi",
    toAddYourCareerHistory: "per aggiungere alla tua carriera"
  },
  educationalQualifications: {
    title: "Formazione",
    login: "Accedi",
    toAddYourEducationalQualifications: "per aggiungere formazione",
    editDataToViewHere: "Modifica qui",
    addEducationalQualification: "Aggiungi formazione",
    degreeCertification: "Titolo di studio",
    organization: "Istituto",
    startDate: "Data di Inizio",
    endDate: "Data di Fine",
    scorePercentage: "Voto",
    country: "Paese",
    city: "Città",
    description: "Descrizione",
    orExpected: "O data prevista"
  },
  skills: {
    title: "Competenze",
    skills: "Competenze",
    languages: "Lingue",
    hobbies: "Interessi"
  },
  personality: {
    title: "Tratti della Personalità",
    chooseYourTop6Strengths: {
      p1: "Scegli 6 punti di forza (Caratteristiche personali che",
      p2: "MEGLIO",
      p3: "ti rappresentano)"
    },
    characteristicsThatBestDescribeYou: {
      p1: "Caratteristiche che",
      p2: "MEGLIO",
      p3: "ti rappresentanoo"
    },
    choose6MoreStrengths: {
      p1: "Scegli altri 6 punti di forza (Caratteristiche che",
      p2: "ti rappresentano",
      p3: "di più)"
    },
    characteristicsThatMostDescribeYou: {
      p1: "Caratteristiche che",
      p2: "ti rappresentano",
      p3: "di più"
    },
    characteristicsThatLeastDescribeYou: {
      p1: "Caratteristiche che",
      p2: "ti rappresentano",
      p3: "MENO"
    },
    keepBuildingYourPersonalBrand: "Continua a lavorare sul tuo personal brand",
    dontBroadcastMyPersonality: "Non mostrare la mia descrizione Personale",
    statements: "Statement",
    cloudTags: "Cloud Tag",
    icons: "Icone",
    personalityStatements: "Descrizione Personale"
  },
  projects: {
    title: "Progetti",
    login: "Accedi",
    toAddYourProjects: "per agiungere i tuoi progetti",
    editDataToViewHere: "modifica qui",
    addProject: "Aggiungi progetto",
    projectTitle: "Titolo",
    organization: "Organizzazione",
    addProjectPicture: "Aggiungi immagine",
    updateProjectPicture: "Carica immagine",
    upto3Mb: "Fino a 3 MB",
    description: "Descrizione"
  },
  logout: {
    title: "Disconnetti"
  },
  editorRightBar: {
    talentCard: 'Talent Card',
    buildYourTalentProfileWith : `Costruisci il tuo profilo di talento con {organizationName}`,
    alex: "Alex",
    alexForContinuousLearning: "Manager dell'Esperienza Adattiva di Apprendimento per la  tua formazione continua",
    templateOptions: "Opzioni Template",
    template: "Template",
    preview: "Anteprima",
    download: "Scarica",
    copyLink: "Copia Link",
    published: "Publicato",
    private: "Privato",
    colorSettings: "Impostazione Colori"
  }
}
