// en, de, fr, pt
export default {
  utils: {
    notAuthenticated: {
      youAreNotAuthenticated: "Não está autenticado.",
      login: "Login",
      toSaveYourData: "para guardar os seus dados."
    },
    noDataAvailable: "Sem informação disponível."
  },
  template: {
    prestige: {
      contactInfo: "INFORMAÇÃO DE CONTACTO",
      skills: "COMPETÊNCIAS",
      languages: "LÍNGUAS",
      hobbies: "HOBBIES",
      careerHistory: "HISTÓRICO PROFISSIONAL",
      educationalQualifications: "QUALIFICAÇÕES EDUCACIONAIS",
      personalityTraits: "TRAÇOS DE PERSONALIDADE",
      competency: "COMPETÊNCIAS",
      placesIHaveWorkedStudiedBefore: "Lugares onde trabalhei/estudei"
    }
  },
  about: {
    title: "Sobre",
    email: "Email",
    youHaveBeenSuccessfullyAuthenticated: "Autenticação feita com sucesso",
    enterYourEmailIdToLogin: "Insira o email para fazer o login",
    clickToAuthenticate: "Clique para autenticar",
    otp: { 
      title: "Insira OTP",
      verify: "Verifique"
    },
    enterOTP: "Insira OTP",
    firstName: "Nome Próprio",
    lastName: "Apenas",
    dob: "Data de Nascimento",
    gender: {
      male: "Masculino",
      female: "Feminino",
      other: "Outro"
    },
    careerObjective: { 
      title: "Objetivo de Carreira",
      placeholder: "As minhas aspirações de carreira são..."
    },
    profession: "Profissão",
    about: { 
      title: "Sobre",
      placeholder: "Um pouco sobre mim... enquanto pessoa"
    },
    videoPitch: {
      title: "Adicione o seu Vídeo de Apresentação",
      size: "Até 10 MB"
    }
  },
  contact: {
    title: "Contacto",
    email: "Email",
    email_2: {
      p1: "Vai ver o seu email aqui, assim que fizer o seu ",
      p2: "login"
    },
    skypeProfile: "Skype",
    contactNumber: "Número de Contacto",
    address: "Morada",
    personalWebsite: "Website Pessoal",
    facebookProfile: "Perfil do Facebook",
    twitterProfile: "Perfil do Twitter",
    linkedinProfile: "Perfil do LinkedIn"
  },
  careerHistory: {
    title: "Histórico Profissional",
    editDataToViewHere: "Edite os dados para ver aqui",
    addCareerHistory: "Adicione histórico profissional",
    showCareerHistoryOnMap: "Mostre Histórico Profissional no Mapa",
    company: "Empresa",
    chTitle: "Cargo",
    startDate: "Data de Início",
    endDate: "Data de Fim",
    description: "Descrição",
    country: "País",
    city: "Cidade",
    iCurrentlyWorkHere: "Trabalho aqui neste momento",
    login: "Login",
    toAddYourCareerHistory: "para adicionar o seu histórico profissioanal"
  },
  educationalQualifications: {
    title: "Qualficações Educacionais",
    login: "Login",
    toAddYourEducationalQualifications: "para adicionar as suas Qualificações Educacionais",
    editDataToViewHere: "Edite os dados para ver aqui",
    addEducationalQualification: "Adicione Qualificações Educacionais",
    degreeCertification: "Grau/Certificação",
    organization: "Organização",
    startDate: "Data de Início",
    endDate: "Data de Fim",
    scorePercentage: "Nota/Percentagem",
    country: "País",
    city: "Cidade",
    description: "Descrição",
    orExpected: "Ou Prevista"
  },
  skills: {
    title: "Competências",
    skills: "Competências",
    languages: "Línguas",
    hobbies: "Hobbies"
  },
  personality: {
    title: "Personalidade",
    chooseYourTop6Strengths: {
      p1: "Escolha os seus 6 pontos mais fortes (Características que ",
      p2: "MELHOR",
      p3: "o descrevem)"
    },
    characteristicsThatBestDescribeYou: {
      p1: "Características que",
      p2: "MELHOR",
      p3: "o descrevem"
    },
    choose6MoreStrengths: {
      p1: "Escolha mais 6 competências (Características que",
      p2: "MAIS",
      p3: "o descrevem)"
    },
    characteristicsThatMostDescribeYou: {
      p1: "Características que",
      p2: "MAIS",
      p3: "o descrevem"
    },
    characteristicsThatLeastDescribeYou: {
      p1: "Características que",
      p2: "MENOS",
      p3: "o descrevem"
    },
    keepBuildingYourPersonalBrand: "Continue a construir a sua imagem pessoal",
    dontBroadcastMyPersonality: "Não mostre a minha personalidade",
    statements: "Afirmações",
    cloudTags: "Cloud Tags",
    icons: "Ícones",
    personalityStatements: "Afirmações de Personalidade"
  },
  projects: {
    title: "Projetos",
    login: "Login",
    toAddYourProjects: "para adicionar os seus Projetos",
    editDataToViewHere: "Edite os dados para ver aqui",
    addProject: "Adicione Projeto",
    projectTitle: "Título",
    organization: "Organização",
    addProjectPicture: "Adicione uma Imagem do Projeto",
    updateProjectPicture: "Atualize a Imagem do Projeto",
    upto3Mb: "Até 3 MB",
    description: "Descrição"
  },
  logout: {
    title: "Sair"
  },
  editorRightBar: {
    talentCard: 'Cartão de Apresentação',
    buildYourTalentProfileWith : `Construa o seu perfil de talento com {organizationName}`,
    alex: "Alex",
    alexForContinuousLearning: "Manager de Experiência de Aprendizagem Adaptável para aprendizagem contínua",
    templateOptions: "Opções de Template",
    template: "Template",
    preview: "Pré-visualização",
    download: "Download",
    copyLink: "Copiar Link",
    published: "Publicado",
    private: "Privado",
    colorSettings: "Definições de Cor"
  }
}
